// import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/vue';
// import { reconnect } from '@wagmi/core';
// import { defineChain } from 'viem';

// const projectId = 'ceae312dfa2834c062761738eb27969b';

// const metadata = {
//   name: 'GIO POA',
//   description: 'GIO POA',
//   url: 'https://web3modal.com',
//   icons: ['https://avatars.githubusercontent.com/u/37784886'],
// };

export default defineNuxtPlugin(() => {
  // const giochain = defineChain({
  //   id: 88089,
  //   name: 'GIO',
  //   network: 'gio',
  //   nativeCurrency: {
  //     decimals: 18,
  //     name: 'GIO',
  //     symbol: 'GIO',
  //   },
  //   rpcUrls: {
  //     default: { http: ['https://connect1.the-gnode.com'] },
  //     public: { http: ['https://connect1.the-gnode.com'] },
  //   },
  //   blockExplorers: {
  //     default: {
  //       name: 'Gioexplorer',
  //       url: 'https://gioexplorer.com',
  //     },
  //   },
  // });
  // const chains = [giochain];
  // const config = defaultWagmiConfig({
  //   chains,
  //   projectId,
  //   metadata,
  //   enableWalletConnect: true,
  //   enableInjected: true,
  //   enableEIP6963: true,
  //   enableCoinbase: true,
  // });
  // reconnect(config);
  // createWeb3Modal({
  //   wagmiConfig: config,
  //   projectId,
  //   enableAnalytics: false,
  // });
  // nuxtApp.provide('wagmiConfig', config);
});
