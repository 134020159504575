<template>
  <NuxtPage />
  <!--<VueQueryDevtools />-->
</template>

<script setup lang="ts">
// import { VueQueryDevtools } from '@tanstack/vue-query-devtools';
import { provide } from 'vue';
import { createWeb3Modal } from '@web3modal/wagmi/vue';
import { reconnect } from '@wagmi/core';
import { projectId, config } from './wagmi';

const {
  public: { APP_NAME },
} = useRuntimeConfig();
const route = useRoute();

reconnect(config);
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: false,
});
provide('wagmiConfig', config);

useHead({
  titleTemplate: route.path === '/' ? '%s' : '%s - ' + APP_NAME,
  bodyAttrs: {
    class: 'bg-black font-sans text-white antialiased',
  },
});
</script>
