export const appPageTransition = false

export const appLayoutTransition = false

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"application-name","content":"GioPulse"},{"name":"apple-mobile-web-app-capable","content":"yes"},{"name":"apple-mobile-web-app-status-bar-style","content":"black-translucent"},{"name":"msapplication-TileColor","content":"#000000"},{"name":"msapplication-TileImage","content":"https://www.giopulse.com/mstile-144x144.png"},{"name":"theme-color","content":"#000000"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"https://www.giopulse.com/apple-touch-icon.png"},{"rel":"icon","type":"image/svg+xml","href":"https://www.giopulse.com/icon.svg"},{"rel":"manifest","href":"https://www.giopulse.com/site.webmanifest"},{"rel":"mask-icon","href":"https://www.giopulse.com/safari-pinned-tab.svg","color":"#e27338"},{"rel":"preconnect","href":"https://use.typekit.net"},{"rel":"stylesheet","href":"https://use.typekit.net/exh1ltu.css"}],"style":[],"script":[],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"trailingSlash":"remove","componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false