import { default as burnmH38zl4EHtMeta } from "/home/giopulse/src/pages/admin/burn.vue?macro=true";
import { default as indexeIIMpGRliCMeta } from "/home/giopulse/src/pages/admin/index.vue?macro=true";
import { default as admin5n9JuRZJkBMeta } from "/home/giopulse/src/pages/admin.vue?macro=true";
import { default as indexFWievacYWtMeta } from "/home/giopulse/src/pages/index.vue?macro=true";
import { default as indexIuwytuZ70hMeta } from "/home/giopulse/src/pages/nodes/[nodeId]/index.vue?macro=true";
import { default as transfer1oZQO3MkAuMeta } from "/home/giopulse/src/pages/nodes/[nodeId]/transfer.vue?macro=true";
import { default as _91nodeId_93tAPbfxyThPMeta } from "/home/giopulse/src/pages/nodes/[nodeId].vue?macro=true";
import { default as indexKniphy0OrlMeta } from "/home/giopulse/src/pages/nodes/index.vue?macro=true";
export default [
  {
    name: admin5n9JuRZJkBMeta?.name ?? undefined,
    path: admin5n9JuRZJkBMeta?.path ?? "/admin",
    meta: admin5n9JuRZJkBMeta || {},
    alias: admin5n9JuRZJkBMeta?.alias || [],
    redirect: admin5n9JuRZJkBMeta?.redirect,
    component: () => import("/home/giopulse/src/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: burnmH38zl4EHtMeta?.name ?? "admin-burn___en",
    path: burnmH38zl4EHtMeta?.path ?? "burn",
    meta: burnmH38zl4EHtMeta || {},
    alias: burnmH38zl4EHtMeta?.alias || [],
    redirect: burnmH38zl4EHtMeta?.redirect,
    component: () => import("/home/giopulse/src/pages/admin/burn.vue").then(m => m.default || m)
  },
  {
    name: indexeIIMpGRliCMeta?.name ?? "admin___en",
    path: indexeIIMpGRliCMeta?.path ?? "",
    meta: indexeIIMpGRliCMeta || {},
    alias: indexeIIMpGRliCMeta?.alias || [],
    redirect: indexeIIMpGRliCMeta?.redirect,
    component: () => import("/home/giopulse/src/pages/admin/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: admin5n9JuRZJkBMeta?.name ?? undefined,
    path: admin5n9JuRZJkBMeta?.path ?? "/de/admin",
    meta: admin5n9JuRZJkBMeta || {},
    alias: admin5n9JuRZJkBMeta?.alias || [],
    redirect: admin5n9JuRZJkBMeta?.redirect,
    component: () => import("/home/giopulse/src/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: burnmH38zl4EHtMeta?.name ?? "admin-burn___de",
    path: burnmH38zl4EHtMeta?.path ?? "burn",
    meta: burnmH38zl4EHtMeta || {},
    alias: burnmH38zl4EHtMeta?.alias || [],
    redirect: burnmH38zl4EHtMeta?.redirect,
    component: () => import("/home/giopulse/src/pages/admin/burn.vue").then(m => m.default || m)
  },
  {
    name: indexeIIMpGRliCMeta?.name ?? "admin___de",
    path: indexeIIMpGRliCMeta?.path ?? "",
    meta: indexeIIMpGRliCMeta || {},
    alias: indexeIIMpGRliCMeta?.alias || [],
    redirect: indexeIIMpGRliCMeta?.redirect,
    component: () => import("/home/giopulse/src/pages/admin/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexFWievacYWtMeta?.name ?? "index___en",
    path: indexFWievacYWtMeta?.path ?? "/",
    meta: indexFWievacYWtMeta || {},
    alias: indexFWievacYWtMeta?.alias || [],
    redirect: indexFWievacYWtMeta?.redirect,
    component: () => import("/home/giopulse/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexFWievacYWtMeta?.name ?? "index___de",
    path: indexFWievacYWtMeta?.path ?? "/de",
    meta: indexFWievacYWtMeta || {},
    alias: indexFWievacYWtMeta?.alias || [],
    redirect: indexFWievacYWtMeta?.redirect,
    component: () => import("/home/giopulse/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91nodeId_93tAPbfxyThPMeta?.name ?? undefined,
    path: _91nodeId_93tAPbfxyThPMeta?.path ?? "/nodes/:nodeId()",
    meta: _91nodeId_93tAPbfxyThPMeta || {},
    alias: _91nodeId_93tAPbfxyThPMeta?.alias || [],
    redirect: _91nodeId_93tAPbfxyThPMeta?.redirect,
    component: () => import("/home/giopulse/src/pages/nodes/[nodeId].vue").then(m => m.default || m),
    children: [
  {
    name: indexIuwytuZ70hMeta?.name ?? "nodes-nodeId___en",
    path: indexIuwytuZ70hMeta?.path ?? "",
    meta: indexIuwytuZ70hMeta || {},
    alias: indexIuwytuZ70hMeta?.alias || [],
    redirect: indexIuwytuZ70hMeta?.redirect,
    component: () => import("/home/giopulse/src/pages/nodes/[nodeId]/index.vue").then(m => m.default || m)
  },
  {
    name: transfer1oZQO3MkAuMeta?.name ?? "nodes-nodeId-transfer___en",
    path: transfer1oZQO3MkAuMeta?.path ?? "transfer",
    meta: transfer1oZQO3MkAuMeta || {},
    alias: transfer1oZQO3MkAuMeta?.alias || [],
    redirect: transfer1oZQO3MkAuMeta?.redirect,
    component: () => import("/home/giopulse/src/pages/nodes/[nodeId]/transfer.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91nodeId_93tAPbfxyThPMeta?.name ?? undefined,
    path: _91nodeId_93tAPbfxyThPMeta?.path ?? "/de/nodes/:nodeId()",
    meta: _91nodeId_93tAPbfxyThPMeta || {},
    alias: _91nodeId_93tAPbfxyThPMeta?.alias || [],
    redirect: _91nodeId_93tAPbfxyThPMeta?.redirect,
    component: () => import("/home/giopulse/src/pages/nodes/[nodeId].vue").then(m => m.default || m),
    children: [
  {
    name: indexIuwytuZ70hMeta?.name ?? "nodes-nodeId___de",
    path: indexIuwytuZ70hMeta?.path ?? "",
    meta: indexIuwytuZ70hMeta || {},
    alias: indexIuwytuZ70hMeta?.alias || [],
    redirect: indexIuwytuZ70hMeta?.redirect,
    component: () => import("/home/giopulse/src/pages/nodes/[nodeId]/index.vue").then(m => m.default || m)
  },
  {
    name: transfer1oZQO3MkAuMeta?.name ?? "nodes-nodeId-transfer___de",
    path: transfer1oZQO3MkAuMeta?.path ?? "transfer",
    meta: transfer1oZQO3MkAuMeta || {},
    alias: transfer1oZQO3MkAuMeta?.alias || [],
    redirect: transfer1oZQO3MkAuMeta?.redirect,
    component: () => import("/home/giopulse/src/pages/nodes/[nodeId]/transfer.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexKniphy0OrlMeta?.name ?? "nodes___en",
    path: indexKniphy0OrlMeta?.path ?? "/nodes",
    meta: indexKniphy0OrlMeta || {},
    alias: indexKniphy0OrlMeta?.alias || [],
    redirect: indexKniphy0OrlMeta?.redirect,
    component: () => import("/home/giopulse/src/pages/nodes/index.vue").then(m => m.default || m)
  },
  {
    name: indexKniphy0OrlMeta?.name ?? "nodes___de",
    path: indexKniphy0OrlMeta?.path ?? "/de/nodes",
    meta: indexKniphy0OrlMeta || {},
    alias: indexKniphy0OrlMeta?.alias || [],
    redirect: indexKniphy0OrlMeta?.redirect,
    component: () => import("/home/giopulse/src/pages/nodes/index.vue").then(m => m.default || m)
  }
]